
/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FOOTER_COMPONENT_KEY = 'FooterComponent'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export interface FooterConfig {
  shouldLoadTrusted: boolean
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const FOOTER_COMPONENT_CONFIG_MAP: FooterConfig = {
  shouldLoadTrusted: false
}
