


























import { Component, Prop, Vue } from 'vue-property-decorator'

import { AggregateTrustedRatingData } from '../../../contracts/trustedShop'

@Component<TrustedShopBadge>({
  name: 'TrustedShopBadge'
})
export class TrustedShopBadge extends Vue {
  @Prop({ type: Object, required: true })
  public readonly aggregateRating!: AggregateTrustedRatingData
}

export default TrustedShopBadge
